<template>
  <div id="app">
    <div v-if="isDev">
      <van-cell title="商品详情" is-link url="/product_details.html?id=58" />
      <van-cell title="套餐详情" is-link url="/server_details.html?id=24" />
      <van-cell title="评估报告" is-link url="/assessment_report.html" />
      <van-cell title="私密评估报告" is-link url="/privateReport.html" />
      <van-cell title="资讯详情" is-link url="/Information_articles.html?id=69" />
      <van-cell title="资讯详情（新）" is-link url="/collegePage.html?id=4" />
      <van-cell title="隐私政策" is-link url="/privacy.html" />
      <van-cell title="用户协议" is-link url="/useAgreement.html" />
      <van-cell title="新手教程" is-link url="/training_help_index.html" />
      <van-cell title="新手教程详细" is-link url="/training_help.html" />
      <van-cell title="问卷结果" is-link url="/question_test_details.html" />
      <van-cell title="账号注销须知" is-link url="/terminate.html" />
      <van-cell title="物流详情" is-link url="/express_details.html" />
      <van-cell title="昕佳康" is-link url="/app_download.html" />
      <van-cell title="document" is-link url="/document.html?id=11" />
      <van-cell title="保修卡" is-link url="/warrantycard.html" />
      <van-cell title="预约" is-link url="/subscribe.html" />
    </div>
    <div v-else>
      昕龙医疗
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed:{
    isDev(){
      return process.env.NODE_ENV==="development"
    }
  }
}
</script>
<style>
  #app{}
  .index{
    width: 200px;
    height: 100px;
    border: 1px solid #eee;
  }
</style>
